import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/SEO'
import { SessionAuthenticator, SessionContext } from 'clouden-components/context/session'
import { withNotificationContext, NotificationContext } from 'clouden-components/context/notification'
import { AccountSettings } from 'clouden-components/views/account-settings'
import qs from 'qs'
import { Trans, withTranslation, WithTranslation } from 'react-i18next'

interface AccountSettingsPageProps extends WithTranslation {
  location: any
  sessionContext: SessionContext
  notificationContext: NotificationContext
}

class AuthenticatedAccountSettingsPage extends React.Component<AccountSettingsPageProps> {
  state = {
  }

  render() {
    const query = qs.parse(this.props.location.search.slice(1))
    return (
      <div className="container">
        <div className="row flex-button-row mt-5 border-bottom">
          <div className="col-lg-8 flex-button-title">
            <h1 className="">Account Settings</h1>
          </div>
          <div className="col-lg-4 flex-button-col">
          </div>
        </div>
        <AccountSettings defaultActiveKey={query.tab || "credits"} location={this.props.location} />
      </div>
    )
  }
}

class AccountSettingsPage extends React.Component<AccountSettingsPageProps> {
  render() {
    return (
      <Layout location={this.props.location}>
        <SEO title={"Account Settings"} description="Account Settings" />
        <SessionAuthenticator>
          <AuthenticatedAccountSettingsPage {...this.props} />
        </SessionAuthenticator>
      </Layout>
    )
  }
}

export default withTranslation()(withNotificationContext(AccountSettingsPage))
